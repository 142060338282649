import React, { useEffect, useRef, useState } from 'react'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { css } from '@emotion/react'
import { Flex, Text } from 'shared/ui'
import { summarizeCount } from 'util/counts'

const flexStyle = css`
  flex: 1 0 10%;
`

function Name({ name }) {
  const [isItemTextOverflowing, setIsItemTextOverflowing] = useState(null)
  const itemTextRef = useRef(null)

  useEffect(() => {
    if (itemTextRef.current) {
      setIsItemTextOverflowing(
        itemTextRef.current.clientWidth < itemTextRef.current.scrollWidth
      )
    }
  }, [])

  const itemText = (
    <span className="grui truncate" ref={itemTextRef}>
      {name}
    </span>
  )

  return (
    <Flex className="grui overflow-hidden">
      {isItemTextOverflowing ? (
        <Tooltip title={name} strategy="fixed">
          {itemText}
        </Tooltip>
      ) : (
        itemText
      )}
    </Flex>
  )
}

// allow this since Name is a very simple component.

export default function LabelText({ name, count, showCount }) {
  if (!showCount) return <Name name={name} />

  return (
    <Flex className="grui overflow-hidden">
      <Name name={name} />
      <Flex css={flexStyle}>
        <Text size="small" className="grui ml-3">
          ({summarizeCount(count)})
        </Text>
      </Flex>
    </Flex>
  )
}
